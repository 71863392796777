@tailwind base;
@tailwind components;
@tailwind utilities;
/* @import url('https://fonts.googleapis.com/css2?family=BIZ+UDPGothic&display=optional'); */

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

body,
html {
  margin: 0;
  padding: 0;
  /* font-family: "BIZ UDPGothic", "Roboto", sans-serif; */
  font-style: normal;
  font-weight: 400;
}

.none {
  display: none;
}
.h-screen {
  height: 100svh!important;
}
.scoring-btn {
  position: absolute;
  bottom: 80px;
  left: 50%;
  transform: translateX(-50%);
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  /* font-family: "BIZ UDPGothic", sans-serif; */
  font-style: normal;
  font-weight: 400;
  font-size: min(2.8vw,16px);
  letter-spacing: .02em;;
  color: #fff;
  background-image: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1),
    rgba(215, 0, 255, 1) 30%,
    rgba(50, 0, 255, 1) 70% 90%
  );
  display: flex;
  justify-content: center;
  align-items: center;
}
.scoringBtnArea button {
  text-decoration: none!important;
}
.slide {
  width: 100%;
  height: 100vh;
  overflow: scroll;
  /*スクロールバー非表示（IE・Edge）*/
  -ms-overflow-style: none;
  /*スクロールバー非表示（Firefox）*/
  scrollbar-width: none;
  overflow-y: auto; 
  scroll-snap-type: y mandatory; 
  overscroll-behavior: contain !important;
}

/*Google Chrome、Safari、Microsoft Edge対応のスクロールバー非表示コード*/
.slide::-webkit-scrollbar {  
  display: none;
}

.snap-item {
  width: 100%;
  height: 100vh; 
  scroll-snap-align: start; 
  scroll-snap-stop: always !important;
}

.slide p {
  width: 100%;
  height: 100vh;
  line-height: 100vh;
  text-align: center;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  color: #fff;
  background: #262727;
  scroll-snap-align: start !important;
}

.imgList {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  scroll-snap-align: start !important;
}

.text-with-outline-shadow {
  text-shadow: 2px 2px 4px #565656;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #333;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
.header-area {
  background-color: rgba(0, 0, 0, 0);
}
.footer-area {
  background-color: rgba(0, 0, 0, 0.9);
}

.footNaviArea {
  .cls-1 {
    fill: #fff;
    stroke-width: 0px;
  }
}

.settingBtn {
  .cls-1 {
    fill: #fff;
    stroke-width: 0px;
  }
}
.settingNaviList .settingBtn {
  .cls-1 {
    fill: #fff;
    stroke-width: 0px;
  }
}

.settingArea .settingNaviLis {
  .cls-1  {fill: #fff}
  .cls-1, .cls-2 { stroke-width: 0px; }
  .cls-2 { fill: #fff; }
}

input:-webkit-autofill {
  box-shadow: 0 0 0px 1000px rgb(31 41 55 / var(--tw-bg-opacity)) inset;
  -webkit-text-fill-color: #fff !important;
  caret-color: #fff;
}


/* フェードイン */
.fade-in {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
}

/* フェードアウト */
.fade-out {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
}

.notificationBtn {
  z-index: 30; /* アイコンがリンクより前面に表示されるように設定 */
  transform: translateY(-50%); /* 位置を微調整 */

}

.settingBtn {
  z-index: 20;
}